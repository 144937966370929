import React, { useEffect, useMemo, useRef, useState } from "react";
import { InboxOutlined, InfoCircleOutlined } from "@ant-design/icons";
import { DeleteIconImg, DeleteIcon, FilesIcon } from "../../../../../images";
import { Custominput } from "../../../../../../bit_components/common/custominput";
import CopyComponent from '../../../../stateless/common/copyComponent';
import {
  Checkbox,
  Col,
  DatePicker,
  Form,
  Popover,
  Row,
  Switch,
  TimePicker,
  Tooltip,
  Upload,
  message,
} from "antd";
import { useDispatch, useSelector } from "react-redux";
import {
  DeviceSelector,
  removeChangedItems,
  removeCopiedItems,
  addToInvalidFields,
  removeFromInvalidFields,
  setChangedItems,
  setCopiedItems,
  setStoredSettings,
  setNewDeviceAssets,
  removeNewDeviceAssets,
  removeExistingDeviceAssets,
  setExistingDeviceAssets
} from "../../../../../../redux/reducers/device/deviceReducer";
import ColorPicker from "../../../../stateless/common/colorPicker/ColorPicker";
import { Dropdown } from "../../../../../../bit_components/common/dropdown";
import {
  DevicePickerFormat,
  jsonData,
} from "../../../../../constants/constants";
import { useTranslation } from "react-i18next";
import moment from "moment";
import { Button } from "../../../../../../bit_components/common/button";
import {
  areUnorderedStringSetsEqual,
  comparable,
  handleEnterKeyPress,
  isDeepEqual,
  removeEmptyValues,
  removeKeys,
} from "../../../../../../utility/utils";
import {
  MultiSelectCheckBox,
  MultiSelectLabel,
} from "../../../../stateless/common/multiSelect/multiSelectButton/multiSelectButton.style";
import secureLocalStorage from "react-secure-storage";
import CustomPassword from "../../../../stateless/common/inputField/customPassword";
import CustomTextArea from "../../../../stateless/common/inputField/customTextArea";

interface DevicePropertiesProps {
  nodeItem: any;
  nodeStack: any;
  selectedSetting: any;
  onSelect: Function;
  checkedList: any;
  selectAll: boolean | null;
  path: string[];
  modifySetting: Function;
  isForbiddenStatus: boolean;
}

const DeviceProperties = (props: DevicePropertiesProps) => {
  let { nodeItem, selectAll, onSelect, path, selectedSetting, modifySetting, isForbiddenStatus } = props;
  const {
    Data,
    Description,
    FullNodeId,
    AllowedValues,
    ControlType,
    DefaultValue,
    Type,
    ReadOnly,
    ValueDelimiter,
    ValidationExpression
  } = nodeItem;

  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [form] = Form.useForm();

  const changeHandlerRef = useRef<NodeJS.Timeout | undefined>();
  const storeRef = useRef<any>();

  const {
    changedItems,
    changedGroups,
    isLocked,
    storedSettings,
    copiedItems,
    configState,
    copiedGroups,
    deviceAssets,
    newDeviceAssets,
    existingDeviceAssets
  } = useSelector(DeviceSelector);

  const disableEdit = useMemo(() => {
    return (
      (copiedItems && Object.keys(copiedItems)?.length > 0) ||
      (copiedGroups && Object.keys(copiedGroups)?.length > 0) ||
      isLocked || isForbiddenStatus ||
      configState?.HasPermissionToEditConfiguration === false ||
      ReadOnly
    );
  }, [copiedItems, isLocked, configState, ReadOnly, copiedGroups]);

  let initialValue = changedItems?.hasOwnProperty(FullNodeId)
    ? changedItems[FullNodeId]?.newData
    : Data;

  const validationMessage =
    Type === "System.Int32"
      ? t(jsonData.IntegerValueOnly)
      : ((Type === "System.Single" || Type === "System.Double")
        ? t(jsonData.FloatValueOnly)
        : t(jsonData.IncorrectValue)
      );

  const rules = [
    {
      pattern: ValidationExpression && new RegExp(ValidationExpression),
      message: validationMessage,
    },
  ];

  const [value, setValue] = useState<any>(initialValue);
  const [showInfoPanel, setShowInfoPanel] = useState(false);
  const [isInvalid, setIsInvalid] = useState<boolean>(false);
  const [imageUrl, setImageUrl] = useState<any>("");
  const [dropdownValue, setDropdownValue] = useState<any>(null);
  const [fileImage, setFileImage] = useState<any>("");

  const isSettingChanged = () => {
    return changedItems?.hasOwnProperty(FullNodeId);
  };

  const resetToDefault = () => {
    setValue(nodeItem?.DefaultValue);
    form.setFieldsValue({
      name: nodeItem?.DefaultValue,
    });
    setShowInfoPanel(false);
  };

  const handleDiscard = () => {
    setValue(Data);
    form.setFieldsValue({
      name: Data,
    });
  };

  const renderRequiredInput = () => {
    const componentMap = {
      multiselect: (
        <MultiSelectComponent
          value={value}
          setValue={setValue}
          disableEdit={disableEdit}
          AllowedValues={AllowedValues}
          ValueDelimiter={ValueDelimiter}
        />
      ),

      fileselect: (
        <FileSelectComponent
          t={t}
          value={value}
          Data={Data}
          disableEdit={disableEdit}
          setValue={setValue}
          nodeItem={nodeItem}
          deviceAssets={deviceAssets}
          dropdownValue={dropdownValue}
          setDropdownValue={setDropdownValue}
          fileImage={fileImage}
          setFileImage={setFileImage}
          newDeviceAssets={newDeviceAssets}
          existingDeviceAssets={existingDeviceAssets}
          dispatch={dispatch}
        />
      ),
      colourpicker: (
        <ColorPickerComponent
          value={value}
          setValue={setValue}
          disableEdit={disableEdit}
        />
      ),
      datepicker: (
        <DatePickerComponent
          value={value}
          setValue={setValue}
          disableEdit={disableEdit}
          t={t}
        />
      ),
      timepicker: (
        <TimePickerComponent
          value={value}
          setValue={setValue}
          disableEdit={disableEdit}
        />
      ),
      "System.Boolean": (
        <SwitchComponent
          value={value}
          setValue={setValue}
          disableEdit={disableEdit}
        />
      ),
      "System.String": (
        <TextFieldComponent
          Data={Data}
          setValue={setValue}
          nodeItem={nodeItem}
          value={value}
          disableEdit={disableEdit}
          t={t}
          form={form}
          rules={rules}
          validationMessage={validationMessage}
          isInvalid={isInvalid}
          setImageUrl={setImageUrl}
        />
      ),
      "System.Int32": (
        <TextFieldComponent
          Data={Data}
          setValue={setValue}
          nodeItem={nodeItem}
          value={value}
          disableEdit={disableEdit}
          t={t}
          form={form}
          rules={rules}
          validationMessage={validationMessage}
          isInvalid={isInvalid}
          setImageUrl={setImageUrl}
        />
      ),
      "System.DateTime": (
        <DateTimeComponent
          value={value}
          setValue={setValue}
          disableEdit={disableEdit}
          t={t}
        />
      ),
      "System.Single": (
        <TextFieldComponent
          Data={Data}
          setValue={setValue}
          nodeItem={nodeItem}
          value={value}
          disableEdit={disableEdit}
          t={t}
          form={form}
          rules={rules}
          validationMessage={validationMessage}
          isInvalid={isInvalid}
          setImageUrl={setImageUrl}
        />
      ),
      "System.Double": (
        <TextFieldComponent
          Data={Data}
          setValue={setValue}
          nodeItem={nodeItem}
          value={value}
          disableEdit={disableEdit}
          t={t}
          form={form}
          rules={rules}
          validationMessage={validationMessage}
          isInvalid={isInvalid}
          setImageUrl={setImageUrl}
        />
      ),
      default: <>Exceptional</>,
    };

    const componentKey = ControlType || Type || "default";
    const RenderComponent: JSX.Element | (() => JSX.Element) =
      componentMap[componentKey] || componentMap.default;

    return typeof RenderComponent === "function"
      ? RenderComponent()
      : RenderComponent;
  };

  useEffect(() => {
    const isNoChange = () => {
      if (Type === "System.Boolean") {
        return Data?.toString() === value?.toString();
      } else if (ControlType === "multiselect") {
        return areUnorderedStringSetsEqual(Data, value);
      }
      return (
        JSON.stringify(comparable(Data)) === JSON.stringify(comparable(value))
      );
    };

    const componentKey = ControlType || Type;
    const getDynamicDelay = () => {
      const delayMap = {
        multiselect: 0,
        fileselect: 0,
        colourpicker: 100,
        datepicker: 0,
        timepicker: 0,
        "System.Boolean": 0,
        "System.String": nodeItem?.AllowedValues?.length > 0 ? 250 : 100,
        "System.Int32": 100,
        "System.DateTime": 0,
        "System.Single": 100,
        "System.Double": 100,
        default: 0,
      };

      return delayMap[componentKey];
    };

    function debounceChange(isNoChange: boolean) {
      clearTimeout(changeHandlerRef.current);
      changeHandlerRef.current = setTimeout(() => {
        if (isNoChange) {
          let response = removeKeys(storeRef.current, FullNodeId)
          let result = removeEmptyValues(response);
          const isEqual = isDeepEqual(result, storeRef.current);
          if (!isEqual) {
            storeRef.current = result;
            dispatch(setStoredSettings({ ...result }));
            dispatch(removeChangedItems(FullNodeId));
            if(componentKey === 'fileselect'){
              dispatch(removeNewDeviceAssets(FullNodeId));
              dispatch(removeExistingDeviceAssets(FullNodeId));
              setDropdownValue("");
            }
          }
        } else {
          let changedItem = {
            [FullNodeId]: { ...nodeItem, newData: value, path },
          };
          let result = modifySetting(
            storeRef.current,
            path,
            changedItem
          );
          const isEqual = isDeepEqual(result, storeRef.current);
          if (!isEqual) {
            storeRef.current = result;
            dispatch(setStoredSettings({ ...result }));
            dispatch(setChangedItems(changedItem));
          }
        }
      }, getDynamicDelay());
    }
    debounceChange(isNoChange());
    if(ValidationExpression){
      const isValid = rules.every(rule => rule?.pattern?.test(value));
      setIsInvalid(!isValid);
    }
  }, [value]);

  useEffect(() => {
    storeRef.current = storedSettings;
  }, [storedSettings]);

  useEffect(() => {    
    if (isInvalid) {
      dispatch(addToInvalidFields(FullNodeId));
    } else {
      dispatch(removeFromInvalidFields(FullNodeId));
    }
  }, [isInvalid]);

  useEffect(() => {
    if (selectAll !== null) {
      if (selectAll) {
        dispatch(setCopiedItems({ [FullNodeId]: {FullNodeId, Data: value, Description: nodeItem?.Description} }));
      } else {
        dispatch(removeCopiedItems(FullNodeId));
      }
    }
  }, [selectAll]);

  const InfoPanelContainer = () => {
    let hide = true;

    const closePopOver = () => {
      setShowInfoPanel(false);
    };

    const handleVisibleChange = (visible: boolean) => {
      if (visible) {
        setShowInfoPanel(visible);
      } else if (!visible && hide) {
        closePopOver();
      }
    };

    const handleKeyDown = (event: any) => {
      if (event.code === "Tab" && !event?.shiftKey && DefaultValue) {
        hide = false;
        setTimeout(() => {
          hide = true;
        }, 200);
      } else if (event?.code === "Tab" && event?.shiftKey) {
        closePopOver();
      }
    };

    const closeOnTab = (event: any) => {
      if (event.code === "Tab" && !event.shiftKey) {
        closePopOver();
      }
    };

    const closeOnEscape = (event: any) => {
      if (event.code === "Escape") {
        closePopOver();
      }
    };

    return (
      <Col className="config-info-popover" onKeyDown={closeOnEscape}>
        <Popover
          trigger={["click", "focus"]}
          placement="bottomLeft"
          content={
            <Row gutter={[0, 5]}>
              <Col span={24}>
                <div className="node-id-container">
                  <div className="propery-node-id ant-input ant-input-disabled">
                    <Tooltip
                      mouseLeaveDelay={0}
                      overlayClassName="dashBoardTooltip"
                      placement="bottom"
                      title={t(jsonData.NodeId)}
                    >
                      <p className="nodeDeviceText folder-items">{FullNodeId}</p>
                    </Tooltip>
                  </div>
                  <span className="node-id-copy-button"><CopyComponent text={FullNodeId}/></span>
                </div>
              </Col>
              {DefaultValue && (
                <Col span={22}>
                  <div className="propery-node-id ant-input ant-input-disabled">
                    <div>
                      <span>{`${t(jsonData.DefaultValue)}: `}</span>
                      <span className="property-default">{DefaultValue}</span>
                    </div>
                    <div className="config-reset-button" onKeyDown={closeOnTab}>
                      <Button
                        tabIndex={0}
                        type="actionButton"
                        onClick={resetToDefault}
                        aria-label={""}
                        role={""}
                        disabled={disableEdit}
                      >
                        <Tooltip
                          mouseLeaveDelay={0}
                          overlayClassName="dashBoardTooltip"
                          placement="bottom"
                          title={t(jsonData.ResetToDefault)}
                        >
                          <span className="reset-default-button">
                            {t(jsonData.ResetToDefault)}
                          </span>
                        </Tooltip>
                      </Button>
                    </div>
                  </div>
                </Col>
              )}
            </Row>
          }
          visible={showInfoPanel}
          onVisibleChange={(visible) => handleVisibleChange(visible)}
        >
          <Tooltip
            mouseLeaveDelay={0}
            overlayClassName="dashBoardTooltip"
            placement="bottom"
            title={t(jsonData.info)}
          >
            <InfoCircleOutlined
              tabIndex={0}
              className="informationIcon customInfoIcon"
              onKeyDown={handleKeyDown}
            />
          </Tooltip>
        </Popover>
      </Col>
    );
  };

  return (
    <div className="mb-30 device-property-main">
      <div className="mb-10 device-property-description">
        <Row>
          <Col span={24}>
            <Row>
              <Col className="select-property">
                <div
                  className={`changed-settings ${
                    isSettingChanged() ? "red-dot" : ""
                  }`}
                />
              </Col>
              {!selectedSetting?.IsGroup && (
                <Col className="copy-image-properties">
                  <Tooltip
                    mouseLeaveDelay={0}
                    overlayClassName="dashBoardTooltip"
                    placement="bottom"
                    title={t(jsonData.Select)}
                  >
                    <Checkbox
                      disabled={
                        (changedItems && Object.keys(changedItems)?.length > 0) ||
                        (changedGroups && Object.keys(changedGroups)?.length > 0) ||
                        isLocked
                      }
                      checked={copiedItems?.hasOwnProperty(FullNodeId)}
                      onChange={(e) => onSelect(e, {FullNodeId: FullNodeId, Data: value, Description: nodeItem?.Description})}
                      value={FullNodeId}
                      onKeyDown={(e) =>
                        handleEnterKeyPress(e, () => onSelect(e, {FullNodeId, Data: value, Description: nodeItem?.Description}))
                      }
                    />
                  </Tooltip>
                </Col>
              )}

              {InfoPanelContainer()}
              {isSettingChanged() && (
                <Col
                  tabIndex={0}
                  onClick={handleDiscard}
                  onKeyDown={(e) => handleEnterKeyPress(e, handleDiscard)}
                >
                  <Tooltip
                    mouseLeaveDelay={0}
                    overlayClassName="dashBoardTooltip"
                    placement="bottom"
                    title={t(jsonData.Discard)}
                  >
                    <img
                      src={DeleteIconImg}
                      alt={t(jsonData.Discard)}
                      className="config-delete-icon"
                    />
                  </Tooltip>
                </Col>
              )}
            </Row>
            <Row>
              <Col span={24} className="property-description">
                <span>{Description}</span>
              </Col>
              {imageUrl &&
                <Col span={24} className="property-description">
                  <img src={imageUrl} alt="preview.png" />
                </Col>
              }
            </Row>
          </Col>
        </Row>
      </div>
      <div className="config-input-fields">{renderRequiredInput()}</div>
    </div>
  );
};

export default DeviceProperties;

const MultiSelectComponent = React.memo(
  ({ value, setValue, disableEdit, AllowedValues, ValueDelimiter }: any) => {
    const handleChange = (event: any) => {
      if (event.key === "Enter") {
        return event.target.click();
      }
      const optionValue = event.target.value;
      const isChecked = event.target.checked;
      if (isChecked) {
        setValue(
          value ? `${value}${ValueDelimiter}${optionValue}` : optionValue
        );
      } else {
        let val = value
          ?.split(ValueDelimiter)
          ?.filter((item: any) => item !== optionValue)
          ?.join(ValueDelimiter);

        // if (val === "") {
        //   val = null;
        // }
        setValue(val);
      }
    };

    return (
      <>
        {AllowedValues?.map((option: any, index: number) => {
          return (
            <div key={index}>
              <label>
                <div className="multi-select-checkbox">
                  <MultiSelectCheckBox>
                    <Checkbox
                      disabled={disableEdit}
                      value={option.Value}
                      name={option.Value}
                      onChange={handleChange}
                      checked={value?.split(",")?.includes(option.Value)}
                      onKeyDown={(e) =>
                        handleEnterKeyPress(e, () => handleChange(e))
                      }
                    />
                  </MultiSelectCheckBox>
                  <MultiSelectLabel className="checkboxLabel ellipsis">
                    {option.Description}
                  </MultiSelectLabel>
                </div>
              </label>
            </div>
          );
        })}
      </>
    );
  }
);

const SwitchComponent = React.memo(({ value, setValue, disableEdit }: any) => {
  const isChecked = () => {
    if (typeof value === "string") {
      return value === "true";
    }
    return Boolean(value);
  };

  return (
    <Switch
      className={`${
        isChecked() ? "config-toggle-on" : "config-toggle-off"
      } config-toggle-switch`}
      checked={isChecked()}
      onChange={(event: any) => setValue(event)}
      disabled={disableEdit}
    />
  );
});

const TextFieldComponent = React.memo((props: any) => {
  const { Data, setValue, nodeItem, value, disableEdit, t, form, rules, isInvalid, validationMessage, setImageUrl } = props;

  const { Obfuscated, AllowedValues, Secured } = nodeItem;
  const handleTextChange = (event: any) => {
    if (event.target.value === "") {
      setValue(Data ? "" : Data);
    } else {
      setValue(event.target.value);
    }
  };

  const prepareDropDownList = (list: any) => {
    return list.map((listItem: any) => {
      return {
        text: listItem?.Description,
        value: listItem?.Value,
        image: listItem?.Image,
      };
    });
  };

  useEffect(()=>{
    if(AllowedValues?.length > 0){  
      const selectedOption =  AllowedValues.find(item => item.Value === value);
      setImageUrl(selectedOption?.Image)
    }
  }, [value])

  return AllowedValues?.length > 0 ? (
    <Form.Item>
      <Dropdown
        optionValue={prepareDropDownList(AllowedValues)}
        onChange={(val: any) => setValue(val)}
        value={value}
        tabIndex={0}
        disabled={disableEdit}
      />
    </Form.Item>
  ) : (
    <Form form={form}>
      {Obfuscated || Secured ? (
        <CustomPassword
          value={value}
          customLabelClass="labelName"
          onChange={handleTextChange}
          disabled={disableEdit}
        />
      ) : (nodeItem?.Type === 'System.String' ? (
        <div className="custom-validation-input">
          <CustomTextArea
            value={value}
            customLabelClass="labelName"
            rules={rules}
            onChange={handleTextChange}
            disabled={disableEdit}
            name="inputField"
            labelName={t("name")}
            initialValue={value}
            validationStatus={isInvalid ? 'error' : 'success'}
          />
          {isInvalid && <div className="validationMessage">{validationMessage}</div>}
        </div>
      ) :(
        <div className="custom-validation-input">
          <Custominput
            value={value}
            customLabelClass="labelName"
            rules={rules}
            onChange={handleTextChange}
            disabled={disableEdit}
            name="inputField"
            labelName={t("name")}
            initialValue={value}
            validationStatus={isInvalid ? 'error' : 'success'}
          />
          {isInvalid && <div className="validationMessage">{validationMessage}</div>}
        </div>

      ))}
    </Form>
  );
});

const ColorPickerComponent = React.memo(
  ({ value, setValue, disableEdit }: any) => {
    return (
      <ColorPicker
        value={value}
        onChange={(val: any) => setValue(val)}
        disabled={disableEdit}
      />
    );
  }
);

const DatePickerComponent = React.memo(
  ({ value, setValue, disableEdit, t }: any) => {
    const dateFormat = "D MMMM, YYYY";
    let localeInfo:any = secureLocalStorage.getItem('locale')

    function formatDate(inputDate: string) {
      const date = moment(inputDate, dateFormat);
      return date.format(DevicePickerFormat.OUTPUT_FORMAT);
    }

    const handleChange = (evt: any, date: any) => {
      if (date?.length) {
        setValue(
          formatDate(moment(date, localeInfo.momentFormat).format(dateFormat))
        );
      } else {
        setValue("");
      }
    };

    return (
      <DatePicker
        placeholder={t(jsonData.SelectDate)}
        format={localeInfo.momentFormat}
        onChange={handleChange}
        suffixIcon={null}
        className="device-config-picker"
        disabled={disableEdit}
        value={
          value ? moment(moment(value).format(dateFormat), dateFormat) : null
        }
      />
    );
  }
);

const TimePickerComponent = React.memo(
  ({ value, setValue, disableEdit }: any) => {
    const timeFormat = "HH:mm:ss";

    function getTime(inputDate) {
      const date = moment(inputDate);
      return date.format("HH:mm:ss");
    }

    function setTime(timeString) {
      const date = moment().format("YYYY-MM-DD");
      const fullDateString = `${date}T${timeString}`;
      return fullDateString;
    }

    const handleTimepicker = (evt: any, startTime: any) => {
      startTime?.length ? setValue(setTime(startTime)) : setValue("");
    };

    return (
      <TimePicker
        onChange={handleTimepicker}
        format={timeFormat}
        className="device-config-picker"
        value={value ? moment(getTime(value), timeFormat) : null}
        disabled={disableEdit}
      />
    );
  }
);

const DateTimeComponent = React.memo(
  ({ value, setValue, disableEdit, t }: any) => {
    const dateTimeFormat = "D MMMM, YYYY" + "HH:mm:ss";

    function formatDateTime(input: string) {
      const formattedDate = moment(input, dateTimeFormat).format(
        DevicePickerFormat.OUTPUT_FORMAT
      );
      return formattedDate;
    }

    const handleChange = (evt: any, date: any) => {
      if (date) {
        const dateTimeString = moment
          .utc(date, DevicePickerFormat.DATE_TIME)
          .format(dateTimeFormat);

        setValue(formatDateTime(dateTimeString));
      } else {
        setValue("");
      }
    };

    return (
      <DatePicker
        placeholder={t(jsonData["SelectDate&Time"])}
        showTime={{
          format: DevicePickerFormat.TIME,
          defaultValue: moment("00:00:00", DevicePickerFormat.TIME),
        }}
        format={DevicePickerFormat.DATE_TIME}
        onChange={handleChange}
        suffixIcon={null}
        className="device-config-picker"
        disabled={disableEdit}
        value={
          value
            ? moment(moment(value).format(dateTimeFormat), dateTimeFormat)
            : null
        }
      />
    );
  }
);

const FileSelectComponent = React.memo(
  ({ t, value, Data, disableEdit, setValue, nodeItem, deviceAssets, dropdownValue, setDropdownValue, fileImage, setFileImage, newDeviceAssets, existingDeviceAssets, dispatch }: any) => {

    let availableAssets = {};

    const populateAvaiableAssets = () => {
      Object.entries(deviceAssets).map(([key,asset]:any) => {
        let regex = nodeItem?.ExtendedProperties?.allowedFileExtensions;
        if(regex.length > 0){
          if(asset?.url.match(regex)){
            availableAssets = {...availableAssets,[key]:asset};
          }
        } else {
          availableAssets = {...availableAssets,[key]:asset};
        }
      });
    }

    const getFileName = (filePath: string) => {
      if (filePath) {
        const segments = filePath.split(/[/\\]/);
        return segments.pop();
      }
    }

    const setImgUrl =  () => {
      let url = FilesIcon;
      if (newDeviceAssets.hasOwnProperty(nodeItem?.FullNodeId)){
        url = newDeviceAssets[nodeItem?.FullNodeId]?.url;
      }
      else if(existingDeviceAssets.hasOwnProperty(nodeItem?.FullNodeId)){
        url = existingDeviceAssets[nodeItem?.FullNodeId]?.url;
        setDropdownValue(existingDeviceAssets[nodeItem?.FullNodeId]?.fileName);
      }
      else if(value === Data){
        let fileName = getFileName(Data);
        if(deviceAssets.hasOwnProperty(fileName)){
          url = deviceAssets[fileName]?.url;
        }
      }
      setFileImage(url);
    }

    populateAvaiableAssets();
    setImgUrl();

    const uploadButton: any = (
      <div>
        <p className="ant-upload-drag-icon draggerIcon">
          <InboxOutlined />
        </p>
        <p className="ant-upload-text">{t(jsonData.UploadLogo)}</p>
        <p className="ant-upload-hint customHint">
          {t(jsonData.ValidImageFormats)}
        </p>
        <p className="ant-upload-hint customHint">
          {t(jsonData.MaxResolution)}
        </p>
        <p className="ant-upload-hint customHint">{t(jsonData.MaxFileSize)}</p>
      </div>
    );
    
    const removeImage = () => {
      setValue("");
      setFileImage("");
      setDropdownValue("");
      dispatch(removeNewDeviceAssets(nodeItem?.FullNodeId));
      dispatch(removeExistingDeviceAssets(nodeItem?.FullNodeId));
    }

    const uploadImage: any = (
      <div>
        <img src={fileImage} alt="" loading="eager" />
        {disableEdit ? (
          <img src={DeleteIcon} alt="delete" className="basicInfoDeleteIcon" />
        ) : (
          <Tooltip
            mouseLeaveDelay={0}
            title={t(jsonData.Delete)}
            placement="bottom"
          >
            <img
              src={DeleteIconImg}
              onClick={() => removeImage()}
              onKeyDown={(e) => handleEnterKeyPress(e, () => removeImage())}
              alt="delete"
              className="basicInfoDeleteIcon"
            />
          </Tooltip>
        )}
      </div>
    );

    const handleBeforeUpload = async (file: any, fileList: any) => {
      let fileExtensions = nodeItem?.ExtendedProperties?.allowedFileExtensions;
      const extensions = fileExtensions
        .match(/\w+\|?/g)
        .map((ext) => ext.replace(/\|/, ""));

      const allowedExtensions = extensions.map(
        (ext) => `image/${ext.toLowerCase()}`
      );

      if (!allowedExtensions.includes(file.type)) {
        message.error("Incorrect file type");
        return Upload.LIST_IGNORE;
      }
    };

    const getBase64 = (file: any) => {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = (error) => reject(error);
      });
    };

    const handleImageChange = async ({ fileList }: any) => {
      if (fileList?.length > 0) {
        const url = await getBase64(fileList?.[0]?.originFileObj);
        const file = fileList[0].originFileObj;
        const fileName = file.name;
        const configurationName = `__CustomAsset__\\${fileName}`;
        const nodeId = nodeItem?.FullNodeId;

        setValue(configurationName);
        dispatch(setNewDeviceAssets({ 
            ...newDeviceAssets, 
            [nodeId]: { name: fileName, url, file, configurationName }
        }));
      }
  };

    const prepareDropDownList = (list: any) => {
      let returnObj = Object.entries(list)?.map(([key,value]: any) => {
        return {
          text: key,
          value: key,
          url: value?.url
        }
      });
      return returnObj;
    }

    const selectAsset = (val: string) => {
      const existingAsset = {
        [nodeItem?.FullNodeId]: {
          ...availableAssets[val],
          fileName: val
        }
      };
      dispatch(removeNewDeviceAssets(nodeItem?.FullNodeId));
      dispatch(setExistingDeviceAssets(existingAsset));
      setDropdownValue(val);
      setValue(availableAssets[val]?.name);
      setFileImage(availableAssets[val]?.url);
    }

    return (
      <>
        <Form.Item>
          <Upload.Dragger
            maxCount={1}
            openFileDialogOnClick={!value}
            onChange={handleImageChange}
            showUploadList={false}
            beforeUpload={handleBeforeUpload}
            className="uploadBasicInfo"
            disabled={disableEdit}
            aria-label={t(jsonData.uploadYourOrganizationLogo)}
          >
            {value !== "" ? uploadImage : uploadButton}
          </Upload.Dragger>
        </Form.Item>

        {Object.keys(availableAssets).length > 0 &&
          <Form.Item>
            <Dropdown
              optionValue={prepareDropDownList(availableAssets)}
              onChange={(val: any) => selectAsset(val)}
              value={dropdownValue}
              tabIndex={0}
              disabled={disableEdit}
              placeholder={t(jsonData.SelectExistingAsset)}
            />
          </Form.Item>
        }
      </>
    );
  }
);
